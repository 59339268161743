import ArrowUpLeft from '../assets/arrow-up-left.svg';

function Button(props) {
   let className = 'button';
   if (props.fill) {
      className += ' button--fill';
   }
   if (props.link) {
      className += ' button--link';
   }
   return (
      <button className={className}>
         {props.children} {props.link && <img src={ArrowUpLeft} alt="" draggable="false" />}
      </button>
   );
}

export default Button;