function FeaturesBlock() {
   return (
      <div className="features-block">
         <div className="features-block__title">Преимущества</div>
         <div className="features-block__content">
            <div className="feature">
               <div className="feature__title">Комплексность</div>
               <div className="feature__desc">
                  Наша работа в интересах клиента всегда включает в себя: моделирование возможных
                  исходов на всех стадиях проекта, анализ рисков, сроков исполнения, экономических и
                  социальных факторов, изучение правовых вопросов и сопоставление их с реалиями и
                  практиками
               </div>
            </div>
            <div className="feature">
               <div className="feature__title">Профессионализм</div>
               <div className="feature__desc">
                  Высокий уровень знаний, опыт специалистов и руководителей проектов в совокупности
                  с сильным корпоративным духом и чувством ответственности дает нам право называть
                  себя профессионалами. Компания заботится о постоянном повышении квалификации своих
                  сотрудников
               </div>
            </div>
            <div className="feature">
               <div className="feature__title">Оперативность</div>
               <div className="feature__desc">
                  Ключ к успешной реализации проекта зачастую лежит в быстром принятии и исполнении
                  решений. В компании «Новатэк» время является активом, а его потери не допускаются.
               </div>
            </div>
            <div className="feature">
               <div className="feature__title">Взаимное уважение</div>
               <div className="feature__desc">
                  Мы бережно относимся к мнению клиентов, партнеров и оппонентов, их внутренним
                  убеждениям, религиозным и политическим взглядам, чувствам и эмоциональному
                  состоянию. Используя этот принцип, мы существенно повышаем эффективность нашей
                  работы, создавая комфортные условия для всех участников процесса.
               </div>
            </div>
         </div>
      </div>
   );
}

export default FeaturesBlock;
