import React from 'react';
import ReactTooltip from 'react-tooltip';

import firebase from '../firebase';
import { SolutionsData } from '../data/SolutionsData';

import SkeletonBlock from '../skeletons/SkeletonBlock';

function ObjectsBlock() {
   const [objects, setObjects] = React.useState([]);
   const [lastDoc, setLastDoc] = React.useState(null);
   const [fetching, setFetching] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const [totalCount, setTotalCount] = React.useState(11);

   const collection = firebase.firestore().collection('objects');

   React.useEffect(() => {
      setLoading(true);
      collection
         .orderBy('createdAt', 'asc')
         .limit(6)
         .get()
         .then((result) => {
            const items = result.docs.map((doc) => doc.data());
            const lastDoc = result.docs[result.docs.length - 1];
            setObjects(items);
            setLastDoc(lastDoc);
         })
         .finally(() => {
            setLoading(false);
         })
         .catch((e) => console.log('Ошибка => ', e));
   }, []);

   React.useEffect(() => {
      try {
         if (fetching) {
            console.log('fetching');
            setLoading(true);
            collection
               .orderBy('createdAt', 'asc')
               .startAfter(lastDoc)
               .limit(6)
               .get()
               .then((result) => {
                  const items = result.docs.map((doc) => doc.data());
                  const lastDoc = result.docs[result.docs.length - 1];
                  setObjects((objects) => [...objects, ...items]);
                  setLastDoc(lastDoc);
                  setLoading(false);
               })
               .finally(() => {
                  setLoading(false);
                  setFetching(false);
               })
               .catch((e) => console.log('Ошибка => ', e));
         }
      } catch (error) {
         setFetching(false);
         setLoading(false);
      }
   }, [fetching]);

   const scrollHandler = (e) => {
      if (
         e.target.documentElement.scrollHeight -
            (e.target.documentElement.scrollTop + window.innerHeight) <
            300 &&
         objects.length !== totalCount
      ) {
         setFetching(true);
      }
   };

   React.useEffect(() => {
      document.addEventListener('scroll', scrollHandler);

      return function () {
         document.removeEventListener('scroll', scrollHandler);
      };
   }, [fetching]);

   return (
      <div className="objects-block__wrapper">
         <div className="objects-block__content">
            {objects &&
               objects.map((item, index) => (
                  <div className="object" key={index}>
                     <div className="object__info">
                        <div className="object__top">
                           <div className="name">{item.name}</div>
                           <div className="address">{item.address}</div>
                        </div>
                        <div className="object__solutions">
                           {SolutionsData.map(
                              (solution, index) =>
                                 item.solutions.includes(index + 1) && (
                                    <div key={index}>
                                       <img
                                          src={solution.icon}
                                          alt=""
                                          draggable="false"
                                          data-tip
                                          data-for={solution.tag}
                                       />
                                       <ReactTooltip
                                          id={solution.tag}
                                          place="top"
                                          type="warning"
                                          effect="solid"
                                       >
                                          <span>{solution.title}</span>
                                       </ReactTooltip>
                                    </div>
                                 )
                           )}
                        </div>
                     </div>
                     <div
                        className="object__photo"
                        style={{
                           backgroundImage: `url(${item.imageUrl})`,
                        }}
                     ></div>
                  </div>
               ))}
            {loading && [1, 2, 3].map((n) => <SkeletonBlock key={n} />)}
         </div>
      </div>
   );
}

export default ObjectsBlock;
