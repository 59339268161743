import React from 'react';
import Shimmer from './Shimmer';

function SkeletonBlock() {
   return (
      <div className="skeleton__wrapper">
         <div className="skeleton">
            <div className="skeleton__info">
               <div>
                  <div className="skeleton__title"></div>
                  <div className="skeleton__address"></div>
               </div>
               <div className="skeleton__solutions"></div>
            </div>
            <div className="skeleton__img"></div>
         </div>
         <Shimmer />
      </div>
   );
}

export default SkeletonBlock;
