import FeaturesBlock from '../components/FeaturesBlock';

function About() {
   document.title = 'О компании – Новатэк';

   return (
      <>
         <div className="section--gray">
            <div className="container">
               <div className="about-page">
                  <div className="page__header">
                     <div className="page__title">О компании</div>
                     <div className="page__desc">
                        С 2012 года компания Новатэк осуществляет проектные и монтажные работы в
                        сфере тепло-, водо- и электроснабжения
                     </div>

                     <div className="numbers">
                        <div className="numbers__item">
                           <div>8</div>
                           <div>лет на рынке</div>
                        </div>
                        <div className="numbers__item">
                           <div>{'>'}100</div>
                           <div>реализованных проектов</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container">
            <FeaturesBlock />
         </div>
      </>
   );
}

export default About;
