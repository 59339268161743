import React from 'react';

function Contacts() {
   document.title = 'Контакты – Новатэк';

   return (
      <>
         <div className="section--gray">
            <div className="container" style={{ paddingBottom: '5em' }}>
               <div className="page__title" style={{ paddingTop: '60px' }}>
                  Контакты
               </div>
               <div className="contacts">
                  <div className="contact">
                     <div className="contact__title">E-mail</div>
                     <div className="contact__desc">info@novatek29.ru</div>
                  </div>
                  <div className="contact">
                     <div className="contact__title">Телефон</div>
                     <div className="contact__desc">+7 8182 62-93-00</div>
                  </div>
                  <div className="contact">
                     <div className="contact__title">Адрес</div>
                     <div className="contact__desc">
                        ​163045, проспект Обводный канал, офис 28, этаж 2
                     </div>
                  </div>
               </div>
               <div className="map"></div>
            </div>
         </div>
      </>
   );
}

export default Contacts;
