import ObjectsBlock from '../components/ObjectsBlock';

function Objects({ items }) {
   document.title = 'Объекты – Новатэк';

   return (
      <div className="section--gray">
         <div className="container">
            <div className="page__title" style={{ paddingTop: '60px' }}>
               Объекты
            </div>
            <ObjectsBlock items={items} />
         </div>
      </div>
   );
}

export default Objects;
