function Footer() {
   return (
      <footer className="footer">
         <div className="footer__phone">
            <a href="tel:+78182629300">+7 8182 62-93-00</a>
         </div>
         <div className="footer__mail">
            <a href="mailto:info@novatek29.ru">info@novatek29.ru</a>
         </div>
         <div className="footer__address">
            <a href="https://go.2gis.com/dpnok">
               163045, проспект Обводный канал, офис&nbsp;28, этаж&nbsp;2
            </a>
         </div>
      </footer>
   );
}

export default Footer;
