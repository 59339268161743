import IconSolution1 from '../assets/icon-solution-1.svg';
import IconSolution2 from '../assets/icon-solution-2.svg';
import IconSolution3 from '../assets/icon-solution-3.svg';
import IconSolution4 from '../assets/icon-solution-4.svg';
import IconSolution5 from '../assets/icon-solution-5.svg';
import IconSolution6 from '../assets/icon-solution-6.svg';
import IconSolution7 from '../assets/icon-solution-7.svg';
import IconSolution8 from '../assets/icon-solution-8.svg';
import IconSolution9 from '../assets/icon-solution-9.svg';

export const SolutionsData = [
   {
      id: 1,
      title: 'Системы отопления',
      url: '/heating',
      tag: 'heating',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F1-min.png?alt=media&token=7cb15afd-86b3-49e4-aa50-111079cc3cde',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F1-full.png?alt=media&token=65b02b3a-dbd2-4fae-b446-82f76f3dd4e1',
      icon: IconSolution1,
      color: 'linear-gradient(80deg, #F44336 0%, #D32F2F 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 2,
      title: 'Системы водоснабжения',
      url: '/water-supply',
      tag: 'water-supply',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F2-min.png?alt=media&token=c19e91f2-8f05-4ca5-8ad6-a59926914e45',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F2-full.png?alt=media&token=e807a234-32dc-4706-8814-a16f22a754d8',
      icon: IconSolution2,
      color: 'linear-gradient(80deg, #2196F3 0%, #1976D2 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 3,
      title: 'Системы канализации',
      url: '/sewerage',
      tag: 'sewerage',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F3-min.png?alt=media&token=caca20eb-9730-405b-a3f9-546e55420b49',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F3-full.png?alt=media&token=4c6b2973-387c-496f-9f04-7c94c0fd75cf',
      icon: IconSolution3,
      color: 'linear-gradient(80deg, #607D8B 0%, #455A64 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 4,
      title: 'Насосные станции',
      url: '/pumping',
      tag: 'pumping',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F4-min.png?alt=media&token=44379aa5-8147-40de-8d2d-9ac9dcb7bd3f',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F4-full.png?alt=media&token=33432207-afcf-42de-83c3-2b9862ba6617',
      icon: IconSolution4,
      color: 'linear-gradient(80deg, #009688 0%, #00695C 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 5,
      title: 'Тепловые пункты',
      url: '/heat-points',
      tag: 'heat-points',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F5-min.png?alt=media&token=4e0b2f9f-b977-4036-ad00-a31777ab0b74',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F5-full.png?alt=media&token=4d4eb97e-73e7-4eb6-aa5e-e703c175b2c6',
      icon: IconSolution5,
      color: 'linear-gradient(80deg, #9C27B0 0%, #7B1FA2 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 6,
      title: 'Системы учёта',
      url: '/accounting',
      tag: 'accounting',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F6-min.png?alt=media&token=e6b44204-e8c3-49b8-aa33-d14c28d8012c',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F6-full.png?alt=media&token=88a1a032-4395-4dfe-8c86-623f36c0cad6',
      icon: IconSolution6,
      color: 'linear-gradient(80deg, #43A047 0%, #388E3C 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 7,
      title: 'Теплоснабжение калориферов',
      url: '/heaters',
      tag: 'heaters',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F7-min.png?alt=media&token=a28e0f7f-616c-442f-956f-d01dbcde2580',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F7-full.png?alt=media&token=7d55f53c-1d98-4bfd-a9c4-dca01e9a99e1',
      icon: IconSolution7,
      color: 'linear-gradient(80deg, #FF9800 0%, #F57C00 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 8,
      title: 'Тепловые сети',
      url: '/heating-network',
      tag: 'heating-network',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F8-min.png?alt=media&token=744f9d7e-db77-472b-a3e2-960ab3b20c16',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F8-full.png?alt=media&token=a7b80734-00b9-4617-834b-76805cb3cce2',
      icon: IconSolution8,
      color: 'linear-gradient(80deg, #795548 0%, #5D4037 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
   {
      id: 9,
      title: 'Системы очистки воды',
      url: '/water-purification',
      tag: 'water-purification',
      slide: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F9-min.png?alt=media&token=5c77d167-ce48-4220-8e23-d4a0c6ceef49',
      image: 'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/solutions%2F9-full.png?alt=media&token=480b68c0-2a5b-4a21-90f0-65f498bc9d33',
      icon: IconSolution9,
      color: 'linear-gradient(80deg, #00BCD4 0%, #0097A7 100%)',
      desc: 'Комплекс конструктивных элементов, предназначенных для получения, переноса и передачи необходимого количества тепла в обогреваемые помещения. Грамотное проектирование и качественный монтаж системы отопления – залог тепла и уюта в доме в течение всего отопительного сезона. Обогрев должен быть качественным, комфортным, надежным, безопасным и экономичным',
   },
];
