import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import Button from './Button';

import { SolutionsData } from '../data/SolutionsData';

function CarouselMobile() {
   const [currentSlide, setCurrentSlide] = useState(0);
   const [sliderRef, slider] = useKeenSlider({
      dragSpeed: 1,
      slidesPerView: 1,
      spacing: 16,
      initial: 0,
      slideChanged(s) {
         setCurrentSlide(s.details().relativeSlide);
      },
   });

   return (
      <div className="carousel__wrapper">
         <div ref={sliderRef} className="carousel keen-slider">
            {SolutionsData.map((solution) => (
               <div
                  className="slide-mobile keen-slider__slide"
                  style={{ background: `${solution.color}` }}
               >
                  <div
                     className="slide-mobile__content"
                     style={{
                        background: `url(${solution.image})`,
                        backgroundPosition: '50% 25%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                     }}
                  >
                     <div className="slide-mobile__top">
                        <div>{solution.id}</div>
                        <div>/ 9</div>
                     </div>
                     <div className="slide-mobile__bottom">
                        <div className="title">{solution.title}</div>
                        <Link to={`/solutions/${solution.tag}`}>
                           <Button fill>Подробнее</Button>
                        </Link>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
}

export default CarouselMobile;
