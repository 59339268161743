import { Link } from 'react-router-dom';

import Button from './Button';

function ServicesBlock() {
   return (
      <div className="services-block">
         <div className="services-block__top">
            <div className="title">Услуги</div>
            <div className="link">
               <Link to="/services">
                  <Button link>Все услуги</Button>
               </Link>
            </div>
         </div>
         <div className="services-block__content">
            <div className="service">
               <div className="service__title">Проектирование</div>
               <div className="service__desc">
                  Осуществляется проектирование коммуникации для&nbsp;нового строительства,
                  реконструкции, капитального ремонта, расширения, технического перевооружения
                  и&nbsp;модернизации зданий и&nbsp;сооружений
               </div>
            </div>
            <div className="service">
               <div className="service__title">Монтаж</div>
               <div className="service__desc">
                  Ведутся работы по&nbsp;монтажу оборудования на&nbsp;объекте. Этапу предшествует
                  подготовка необходимых заготовок и&nbsp;узлов в&nbsp;монтажном цехе. Проведение
                  необходимых испытаний и&nbsp;пусконаладочных работ
               </div>
            </div>
            <div className="service">
               <div className="service__title">Сервис</div>
               <div className="service__desc">
                  Сервисное обслуживание смонтированных систем, в&nbsp;рамках которого
                  осуществляется периодический дистанционный и&nbsp;местный контроль за&nbsp;работой
                  регулирующих устройств и&nbsp;оборудования
               </div>
            </div>
         </div>
      </div>
   );
}

export default ServicesBlock;
