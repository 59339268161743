import React from 'react';
import { Route, Switch } from 'react-router-dom';

import firebase from './firebase';
import { v4 as uuidv4 } from 'uuid';

import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Objects from './pages/Objects';
import Contacts from './pages/Contacts';
import SolutionDetails from './pages/SolutionDetails';
import NotFound from './pages/NotFound';

import './styles/app.scss';

function App() {
   // const collection = firebase.firestore().collection('objects');
   // React.useEffect(() => {
   //    collection
   //       .doc()
   //       .set({
   //          name: 'Жилой комплекс «Флагман»',
   //          address: 'пр. Ломоносова, 204',
   //          imageUrl:
   //             'https://firebasestorage.googleapis.com/v0/b/novatek-app.appspot.com/o/objects%2F%D0%BF%D1%80.%20%D0%9B%D0%BE%D0%BC%D0%BE%D0%BD%D0%BE%D1%81%D0%BE%D0%B2%D0%B0%2C%20204.png?alt=media&token=39f8c0a8-e903-4646-9658-22676b80cbb7',
   //          solutions: [1, 2, 3, 5],
   //          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
   //       })
   //       .then(() => {
   //          console.log('Document successfully written!');
   //       })
   //       .catch((error) => {
   //          console.error('Error writing document: ', error);
   //       });
   // }, []);

   return (
      <>
         <ScrollToTop />
         <section className="section--gray">
            <div className="container">
               <Header />
            </div>
         </section>

         <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} exact />
            <Route path="/services" component={Services} exact />
            <Route path="/objects" component={Objects} exact />
            <Route path="/contacts" component={Contacts} exact />
            <Route path="/solutions/:tag" component={SolutionDetails} />
            <Route component={NotFound} />
         </Switch>

         <section className="section--black">
            <div className="container">
               <Footer />
            </div>
         </section>
      </>
   );
}

export default App;
