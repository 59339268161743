import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/logo.svg';
import mail from '../assets/icon-mail.svg';
import burger from '../assets/icon-burger.svg';
import close from '../assets/icon-close.svg';

function Header() {
   const [sidebar, setSidebar] = React.useState(false);
   const sidebarElement = React.useRef();

   const toogleSidebar = () => setSidebar(!sidebar);

   const handleOutsideClick = (e) => {
      if (!e.path.includes(sidebarElement.current)) {
         setSidebar(false);
      }
   };

   React.useEffect(() => {
      document.body.addEventListener('click', handleOutsideClick);
      const sidebarBlock = document.querySelector('.header__nav--mobile');
   }, []);

   return (
      <header className="header__wrapper">
         <div className="header">
            <nav className="header__nav">
               <div className="header__logo">
                  <Link to="/">
                     <img width="165" src={logo} alt="Логотип" draggable="false" />
                  </Link>
               </div>
               <div className="header__links">
                  <Link to="/about">О компании</Link>
                  <Link to="/services">Услуги</Link>
                  <Link to="/objects">Объекты</Link>
                  <Link to="/contacts">Контакты</Link>
               </div>
            </nav>
            <div className="header__contact">
               <a href="tel:+78182629300">+7 8182 62-93-00</a>
               <a href="mailto:info@novatek29.ru">
                  <img
                     width="24"
                     height="24"
                     src={mail}
                     alt="Сообщение в Новатэк"
                     draggable="false"
                  />
               </a>
            </div>
            <div className="header__mobile" ref={sidebarElement}>
               <div className="header__menu">
                  {sidebar ? (
                     <img
                        width="24"
                        height="24"
                        src={close}
                        alt="Меню"
                        draggable="false"
                        onClick={toogleSidebar}
                     />
                  ) : (
                     <img
                        width="24"
                        height="24"
                        src={burger}
                        alt="Меню"
                        draggable="false"
                        onClick={toogleSidebar}
                     />
                  )}
               </div>
               <nav className={!sidebar ? 'header__nav--mobile disabled' : 'header__nav--mobile'}>
                  <Link onClick={toogleSidebar} to="/about">
                     О компании
                  </Link>
                  <Link onClick={toogleSidebar} to="/services">
                     Услуги
                  </Link>
                  <Link onClick={toogleSidebar} to="/objects">
                     Объекты
                  </Link>
                  <Link onClick={toogleSidebar} to="/contacts">
                     Контакты
                  </Link>
               </nav>
            </div>
         </div>
      </header>
   );
}

export default Header;
