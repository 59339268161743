import HeroBlock from '../components/HeroBlock';
import Carousel from '../components/Carousel';
import CarouselMobile from '../components/CarouselMobile';
import ServicesBlock from '../components/ServicesBlock';
import ObjectsPreview from '../components/ObjectsPreview';

function Home() {
   document.title = 'Главная – Новатэк';
   const screenWidth = window.innerWidth;

   return (
      <>
         <div className="section--gray">
            <div className="container">
               <HeroBlock />
               {screenWidth > 768 ? <Carousel /> : <CarouselMobile />}
            </div>
         </div>
         <div className="container">
            <ServicesBlock />
         </div>
         <div className="section--gray">
            <div className="container">
               <ObjectsPreview />
            </div>
         </div>
      </>
   );
}

export default Home;
