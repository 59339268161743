function HeroBlock() {
   return (
      <div className="hero">
         <div className="hero__title">Новатэк</div>
         <div className="hero__desc">
            Современные инженерные системы в&nbsp;частных домах и&nbsp;на&nbsp;объектах коммерческой
            недвижимости в&nbsp;городе Архангельске
         </div>
      </div>
   );
}

export default HeroBlock;
