import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
   apiKey: 'AIzaSyC8Xc1OQ1n6FJeO1rbhoDrlLPV5kXyQoCg',
   authDomain: 'novatek-app.firebaseapp.com',
   databaseURL: 'https://novatek-app-default-rtdb.europe-west1.firebasedatabase.app',
   projectId: 'novatek-app',
   storageBucket: 'novatek-app.appspot.com',
   messagingSenderId: '731185692465',
   appId: '1:731185692465:web:0dc9c644efaa419d2efa10',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
