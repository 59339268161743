import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import Button from './Button';

import { SolutionsData } from '../data/SolutionsData';

function Carousel() {
   const [currentSlide, setCurrentSlide] = useState(0);
   const [sliderRef, slider] = useKeenSlider({
      dragSpeed: 1,
      slidesPerView: 1,
      spacing: 32,
      initial: 0,
      slideChanged(s) {
         setCurrentSlide(s.details().relativeSlide);
      },
   });

   return (
      <div className="carousel__wrapper">
         <div className="navigation-wrapper">
            <div ref={sliderRef} className="carousel keen-slider">
               {SolutionsData.map((solution) => (
                  <div
                     key={solution.id}
                     className="slide keen-slider__slide number-slide1"
                     style={{ background: `${solution.color}` }}
                  >
                     <div
                        className="content"
                        style={{
                           background: `url(${solution.slide})`,
                           backgroundPosition: 'center center',
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'cover',
                        }}
                     >
                        <div className="top">
                           <h2 className="title">{solution.title}</h2>
                           <div className="number">{solution.id} / 9</div>
                        </div>
                        <div className="bottom">
                           <Link to={`/solutions/${solution.tag}`}>
                              <Button fill>Подробнее</Button>
                           </Link>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
            {slider && (
               <>
                  <ArrowLeft
                     onClick={(e) => e.stopPropagation() || slider.prev()}
                     disabled={currentSlide === 0}
                  />
                  <ArrowRight
                     onClick={(e) => e.stopPropagation() || slider.next()}
                     disabled={currentSlide === slider.details().size - 1}
                  />
               </>
            )}
         </div>
      </div>
   );
}

function ArrowLeft(props) {
   const disabeld = props.disabled ? ' arrow--disabled' : '';
   return (
      <svg
         onClick={props.onClick}
         className={'arrow arrow--left' + disabeld}
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M19 12L5 12"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M12 19L5 12L12 5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

function ArrowRight(props) {
   const disabeld = props.disabled ? ' arrow--disabled' : '';
   return (
      <svg
         onClick={props.onClick}
         className={'arrow arrow--right' + disabeld}
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M5 12H19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M12 5L19 12L12 19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   );
}

export default Carousel;
