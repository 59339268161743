function NotFound() {
   document.title = 'Не найдено – Новатэк';

   return (
      <>
         <div className="section--gray">
            <div className="container">
               <div className="page__header" style={{ paddingBottom: '480px' }}>
                  <div className="page__title">Ошибка 404</div>
                  <div className="page__desc">Страница не найдена</div>
               </div>
            </div>
         </div>
      </>
   );
}

export default NotFound;
