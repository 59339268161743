function Services() {
   document.title = 'Услуги – Новатэк';

   return (
      <>
         <div className="section--gray">
            <div className="container">
               <div className="about-page">
                  <div className="page__header">
                     <div className="page__title">Услуги</div>
                     <div className="page__desc">
                        Предлагаемый нами комплексный подход позволит получить надежную
                        и&nbsp;качественную систему, отвечающую последним тенденциям в&nbsp;области
                        энергосбережения и&nbsp;повышения энергетической эффективности
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="services-block">
               <div className="services-block__content">
                  <div className="service">
                     <div className="service__title">Проектирование</div>
                     <div className="service__desc">
                        Осуществляется проектирование коммуникации для&nbsp;нового строительства,
                        реконструкции, капитального ремонта, расширения, технического перевооружения
                        и&nbsp;модернизации зданий и&nbsp;сооружений
                     </div>
                  </div>
                  <div className="service">
                     <div className="service__title">Монтаж</div>
                     <div className="service__desc">
                        Ведутся работы по&nbsp;монтажу оборудования на&nbsp;объекте. Этапу
                        предшествует подготовка необходимых заготовок и&nbsp;узлов в&nbsp;монтажном
                        цехе. Проведение необходимых испытаний и&nbsp;пусконаладочных работ
                     </div>
                  </div>
                  <div className="service">
                     <div className="service__title">Сервис</div>
                     <div className="service__desc">
                        Сервисное обслуживание смонтированных систем, в&nbsp;рамках которого
                        осуществляется периодический дистанционный и&nbsp;местный контроль
                        за&nbsp;работой регулирующих устройств и&nbsp;оборудования
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Services;
