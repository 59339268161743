import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import firebase from '../firebase';
import Button from '../components/Button';

import { SolutionsData } from '../data/SolutionsData';
import SkeletonBlock from '../skeletons/SkeletonBlock';

function SolutionDetails() {
   const [objects, setObjects] = React.useState(null);
   const [loading, setLoading] = React.useState(false);

   const { tag } = useParams();
   const solution = SolutionsData.filter((solution) => {
      return solution.tag === tag;
   })[0];

   document.title = solution.title + ' – Новатэк';

   React.useEffect(() => {
      const collection = firebase.firestore().collection('objects');
      setLoading(true);
      collection
         .where('solutions', 'array-contains', solution.id)
         .orderBy('createdAt', 'desc')
         .limit(6)
         .get()
         .then((item) => {
            const items = item.docs.map((doc) => doc.data());
            setObjects(items);
            setLoading(false);
         })
         .catch((e) => console.log('Ошибка загрузки данных => ', e));
   }, [solution]);

   return (
      <>
         <div className="section--gray" style={{ background: `${solution.color}`, color: '#fff' }}>
            <div className="container">
               <div className="solution">
                  <div className="solution__info">
                     <div className="solution__title">{solution.title}</div>
                     <div className="solution__desc">{solution.desc}</div>
                  </div>
                  <div
                     className="solution__img"
                     style={{ backgroundImage: `url(${solution.image})` }}
                  ></div>
               </div>
            </div>
         </div>
         <div className="section--gray">
            <div className="container">
               <div className="objects-block">
                  <div className="objects-block__top">
                     <div className="title">Внедрено</div>
                     <div className="link">
                        <Link to="/objects">
                           <Button link>Все объекты</Button>
                        </Link>
                     </div>
                  </div>
                  <div className="objects-block__content">
                     {objects &&
                        objects.map((item, index) => (
                           <div className="object" key={index}>
                              <div className="object__info">
                                 <div className="object__top">
                                    <div className="name">{item.name}</div>
                                    <div className="address">{item.address}</div>
                                 </div>
                                 <div className="object__solutions">
                                    {SolutionsData.map(
                                       (solution, index) =>
                                          item.solutions.includes(index + 1) && (
                                             <div key={index}>
                                                <img
                                                   src={solution.icon}
                                                   alt=""
                                                   draggable="false"
                                                   data-tip
                                                   data-for={solution.tag}
                                                />
                                                <ReactTooltip
                                                   id={solution.tag}
                                                   place="top"
                                                   type="warning"
                                                   effect="solid"
                                                >
                                                   <span>{solution.title}</span>
                                                </ReactTooltip>
                                             </div>
                                          )
                                    )}
                                 </div>
                              </div>
                              <div
                                 className="object__photo"
                                 style={{
                                    backgroundImage: `url(${item.imageUrl})`,
                                 }}
                              ></div>
                           </div>
                        ))}
                     {loading && [1, 2, 3].map((n) => <SkeletonBlock key={n} />)}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default SolutionDetails;
